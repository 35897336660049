<template>
<!--v-loading.fullscreen.lock="isShowLoading"
       element-loading-text="数据加载中..."
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)"-->
  <div class="paging-ecg-container"
       v-loading.fullscreen.lock="isShowLoading"
       element-loading-text="数据加载中..."
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)"
  >

    <div id="ecg-view-wrapper">

      <EcgChartView ecg-view-id="ecgview1" ref="ecgChart1" v-show="isShowEcgView1"/>
      <EcgChartView ecg-view-id="ecgview2" ref="ecgChart2" v-show="isShowEcgView2"/>
      <EcgChartView ecg-view-id="ecgview3" ref="ecgChart3" v-show="isShowEcgView3"/>
    </div>

    <el-pagination
        background
        :hide-on-single-page="true"
        layout="prev, pager, next"
        :page-size="1"
        :total="totalPage"
        :pager-count="9"
        :current-page="currentPage" @current-change="onPageChange($event)">
    </el-pagination>
  </div>


</template>

<script>
import Vue from "vue";
import EcgChartView from "@/components/EcgChartView";

export default {
  name: "PagingEcgChartView",
  components: {EcgChartView},
  data() {
    return {
      currentPage: 1,
      totalPage: 1,
      currentEcgIndex: 0,
      isShowEcgView1: false,
      isShowEcgView2: false,
      isShowEcgView3: false,
      loadingInstance: null,
      isShowLoading: false,
      ecgArrayLen:0,
      sliceSize:0,
      ecgViewCount:0,
      ecgDataArray:[],
    }
  },

  mounted() {

  },
  destroyed() {
    console.log("pagingEcgView destroyed");
    this.ecgDataArray = null;
    this.closeLoading();
    this.$store.commit("setEcgFile",null);
    this.$store.commit("releaseArray");
  },
  methods: {

    loadData(){
      this.onChartViewMounted();
    },


      onPageChange(page) {
      // this.$eventBus.$emit("page-change");
        let that = this;
       // this.showLoading();
      this.currentPage = page;
      console.log(`onPageChange = ${page}  currentPage = ${this.currentPage}`);
      that.showLoading();
      setTimeout(()=>{
        let result = new Promise(function (resolve, reject){
          console.log("promise start")
          for (let i = (page - 1) * 3; i < page * 3; i++) {
            if (i < that.ecgViewCount) {
              let ecgArr  = [];
              if (i + 1 === that.ecgViewCount) {
                ecgArr = that.ecgDataArray.slice(i * that.sliceSize);
              } else {
                ecgArr = that.ecgDataArray.slice(i * that.sliceSize, i * that.sliceSize + that.sliceSize);
              }
              if (i % 3 === 0) {
                that.isShowEcgView1 = true;
                that.$refs.ecgChart1.asyncRefreshChartView(ecgArr, i,function (){
                  console.log(`callback  EcgChartView ${i} 刷新完成`);
                });
              } else if (i % 3 === 1) {
                that.isShowEcgView2 = true;
                that.$refs.ecgChart2.asyncRefreshChartView(ecgArr, i,function (){
                  console.log(`callback  EcgChartView ${i} 刷新完成`);
                });
              } else if (i % 3 === 2) {
                that.isShowEcgView3 = true;
                that.$refs.ecgChart3.asyncRefreshChartView(ecgArr, i,function (){
                  console.log(`callback  EcgChartView ${i} 刷新完成`);
                });
              }
            } else {
              if (i % 3 === 0) {
              } else if (i % 3 === 1) {
                that.isShowEcgView2 = false;
              } else if (i % 3 === 2) {
                that.isShowEcgView3 = false;
              }
            }

          }
          console.log("promise end");
          resolve();
        });
        result.then(function (){
          console.log("************翻页加载完成***************");
          that.onPageChangeEnd();
        }).catch(function (err){
          console.log(`promise err = ${err}`);
        }).finally(function (){
          console.log(`promise finally`);
        });
      },50);

      // setTimeout(function (){
      //
      //   // for (let i = (page - 1) * 3; i < page * 3; i++) {
      //   //   if (i < that.ecgViewCount) {
      //   //     let ecgArr  = [];
      //   //     if (i + 1 === that.ecgViewCount) {
      //   //       ecgArr = that.ecgDataArray.slice(i * that.sliceSize);
      //   //
      //   //     } else {
      //   //
      //   //       ecgArr = that.ecgDataArray.slice(i * that.sliceSize, i * that.sliceSize + that.sliceSize);
      //   //
      //   //     }
      //   //
      //   //     if (i % 3 === 0) {
      //   //       that.isShowEcgView1 = true;
      //   //       that.$refs.ecgChart1.refreshChartView(ecgArr, i);
      //   //     } else if (i % 3 === 1) {
      //   //       that.isShowEcgView2 = true;
      //   //       that.$refs.ecgChart2.refreshChartView(ecgArr, i);
      //   //     } else if (i % 3 === 2) {
      //   //       that.isShowEcgView3 = true;
      //   //       that.$refs.ecgChart3.refreshChartView(ecgArr, i);
      //   //     }
      //   //   } else {
      //   //     if (i % 3 === 0) {
      //   //
      //   //     } else if (i % 3 === 1) {
      //   //       that.isShowEcgView2 = false;
      //   //     } else if (i % 3 === 2) {
      //   //       that.isShowEcgView3 = false;
      //   //     }
      //   //   }
      //   //
      //   // }
      //   //翻页加载完成
      //   console.log("************翻页加载完成***************");
      //   that.onPageChangeEnd();
      //
      //
      // },100);

    },
    onChartViewMounted() {

      if (this.$store.state.isRawEcgArray){
        this.ecgArrayLen = this.$store.state.ecgRawArrayLength;
      }else {
        this.ecgArrayLen = this.$store.state.ecgFileArrayLength;
      }
      this.sliceSize = this.$store.state.sliceSize;
      this.ecgViewCount = this.$utils.getTotalEcgHourCount(this.ecgArrayLen,this.sliceSize);
      if (this.ecgViewCount % 3 === 0) {
        this.totalPage = this.ecgViewCount / 3;
      } else {
        this.totalPage = Math.trunc(this.ecgViewCount / 3) + 1;
      }
      this.ecgDataArray = this.$store.state.isRawEcgArray? this.$store.state.ecgRawArray : this.$store.state.ecgFiltArray;
      console.log("****************************PagingEcgView total = " + this.totalPage + "********************************");
      console.log(`isRawEcg  = ${this.$store.state.isRawEcgArray} ecgArrayLen = ${this.ecgArrayLen} sliceSize = ${this.sliceSize}`);
      this.isShowEcgView1 = true;

      this.$refs.ecgChart1.refreshChartView(this.ecgDataArray.slice(0,this.sliceSize), 0);
      if (this.ecgViewCount < 2) {
        console.log("**************onChartView1Mounted****************");
        this.onPageChangeEnd();
      }
      if (this.ecgViewCount >= 2) {

        this.isShowEcgView2 = true;
        this.$refs.ecgChart2.refreshChartView(this.ecgDataArray.slice(this.sliceSize,this.sliceSize*2), 1);
        if (this.ecgViewCount < 3) {
          console.log("**************onChartView2Mounted****************");
          this.onPageChangeEnd();
        }
      }
      if (this.ecgViewCount >= 3) {

        this.isShowEcgView3 = true;
        this.$refs.ecgChart3.refreshChartView(this.ecgDataArray.slice(this.sliceSize*2,this.sliceSize*3), 2);
        //本页波形加载完成
        console.log("**************onChartView3Mounted****************");
        this.onPageChangeEnd();
      }

    },

    onPageChangeEnd(){
      this.$eventBus.$emit("page-change-end");
      this.closeLoading()
    },
    showLoading(){
      // this.isShowLoading = true;
       this.loadingInstance = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      console.log("showLoading");
    },
    closeLoading(){
      // this.isShowLoading = false;
      if (this.loadingInstance !== null){
        this.loadingInstance.close();
      }
      console.log("closeLoading")
    },

  }
}
</script>

<style scoped>
.el-pagination {
  text-align: center;
}
</style>