// 导出页面为PDF格式
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
export default {
    install(Vue, options) {
        Vue.prototype.getPdf = function (title) {
            // var element = document.querySelector('#ecg-view-container'); // 这个dom元素是要导出pdf的div容器
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            var pdfContent = document.getElementById("ecg-view-container");
            var width = pdfContent.offsetWidth; //获取dom 宽度
            var height = pdfContent.offsetHeight; //获取dom 高度
            console.log(`width:${width} height:${height}`);
            var canvas = document.createElement("canvas"); //创建一个canvas节点
            var scale = 3; //定义任意放大倍数 支持小数，越大越清晰
            var offsetTop = pdfContent.offsetTop;
            var rect = pdfContent.getBoundingClientRect();//获取元素相对于视察的偏移量
            canvas.width = width * scale; //定义canvas 宽度 * 缩放
            canvas.height = (height + offsetTop) * scale; //定义canvas（高度 +偏移量 ）*缩放
            var content = canvas.getContext("2d");
            content.scale(scale, scale); //获取context,设置scale
            content.translate(-rect.left, -rect.top);//设置context位置，值为相对于视窗的偏移量负值，让图片复位
            var opts = {
                background: "#fff",
                scale: scale, // 添加的scale 参数
                // canvas: canvas, //自定义 canvas
                // logging: true, //日志开关，便于查看html2canvas的内部执行流程
                width: width, //dom 原始宽度
                height: height,
                useCORS: true, // 【重要】开启跨域配置
                allowTaint: true,
                taintTest: false

            };
            var pdf = new jsPDF('', 'pt', 'a4');
            let that= this;
            html2canvas(pdfContent, opts).then(function (canvas) {
                downloadCanvasWithMargin(canvas);
            })
            //-----------------------

            function downloadCanvasWithMargin(canvas) {
                //关闭锯齿
                var context = canvas.getContext('2d');
                context.mozImageSmoothingEnabled = false;
                context.webkitImageSmoothingEnabled = false;
                context.msImageSmoothingEnabled = false;
                context.imageSmoothingEnabled = false;

                let contentWidth = canvas.width;
                let contentHeight = canvas.height;
                //一页pdf显示html页面生成的canvas高度;
                let pageHeight = contentWidth / 595.28 * 841.89;
                //未生成pdf的html页面高度
                let leftHeight = contentHeight;
                let cutStartHeight = 0;
                let canvasArr = [];
                if (leftHeight > pageHeight) {
                    while (leftHeight > 0) {
                        //复制出(0, cutStartHeight)开始，contentWidth宽和pageHeight高的图片
                        let cutImage = context.getImageData(0, cutStartHeight, contentWidth, pageHeight);
                        //从图像底部开始校验颜色，直到验证到以白色结尾才开始切图像
                        let moveUpPx = 0; //上移px数
                        let totalLength = cutImage.data.length;
                        let lineLength = 4 * contentWidth * 1; //一行的data容量
                        let leftLength = totalLength - lineLength;
                        let rightLength = totalLength;
                        let circle = 0; //循环次数
                        let pointRatio = 0.98; //一行给定颜色的占比系数
                        let pointRatioNum = contentWidth * pointRatio;
                        while (circle === moveUpPx) {
                            circle++;
                            let ignorePoint = 0;
                            for (let i = leftLength; i < rightLength; i += 4) {
                                if (cutImage.data[i] === 255 || cutImage.data[i] === 251) {
                                    if (cutImage.data[i] === cutImage.data[i + 1] && cutImage.data[i] === cutImage.data[i + 2]) {
                                        ignorePoint++;
                                    }
                                }
                            }
                            if (ignorePoint < pointRatioNum) {
                                leftLength -= lineLength;
                                rightLength -= lineLength;
                                moveUpPx++;
                            }
                        }
                        if (moveUpPx > 0) {
                            cutImage = context.getImageData(0, cutStartHeight, contentWidth, pageHeight - moveUpPx);
                        }
                        leftHeight -= pageHeight - moveUpPx;
                        cutStartHeight += pageHeight - moveUpPx;
                        //创建新图片
                        let nCanvas = document.createElement("canvas"); //创建一个canvas节点
                        nCanvas.width = canvas.width;
                        nCanvas.height = pageHeight - moveUpPx;
                        let nContext = nCanvas.getContext("2d");
                        nContext.mozImageSmoothingEnabled = false;
                        nContext.webkitImageSmoothingEnabled = false;
                        nContext.msImageSmoothingEnabled = false;
                        nContext.imageSmoothingEnabled = false;
                        nContext.putImageData(cutImage, 0, 0)
                        canvasArr.push(nCanvas);
                    }
                } else {
                    canvasArr.push(canvas);
                }
                //处理尾页图片透明背景
                let lastCanvas = canvasArr[canvasArr.length - 1];
                let ctx = lastCanvas.getContext("2d");
                // 将canvas的透明背景设置成白色--解决translate后便黑色背景的问题
                let imageData = ctx.getImageData(0, 0, lastCanvas.width, lastCanvas.height);
                for (var i = 0; i < imageData.data.length; i += 4) {
                    // 当该像素是透明的，则设置成白色
                    if (imageData.data[i + 3] === 0) {
                        imageData.data[i] = 255;
                        imageData.data[i + 1] = 255;
                        imageData.data[i + 2] = 255;
                        imageData.data[i + 3] = 255;
                    }
                }
                ctx.putImageData(imageData, 0, 0);

                // var pdf = new jsPDF('', 'pt', 'a4');
                let leftMargin = 20;
                let topMargin = 20;
                let a4Width = 595.28;
                let a4Height = 841.89;
                let imgWidth = a4Width - leftMargin * 2;
                let imgHeight = a4Height - 2 * topMargin;
                for (let i = 0; i < canvasArr.length; i++) {
                    let curCanvas = canvasArr[i];

                    let pageData = curCanvas.toDataURL('image/jpeg', 0.5);
                    //小于切分的高度，要换算比例高
                    if (curCanvas.height < pageHeight) {
                        let ratioHeight = imgWidth / curCanvas.width * curCanvas.height;
                        pdf.addImage(pageData, 'JPEG', leftMargin, topMargin, imgWidth, ratioHeight);
                    } else {
                        pdf.addImage(pageData, 'JPEG', leftMargin, topMargin, imgWidth, imgHeight);
                    }
                    if (i < (canvasArr.length - 1)) {
                        pdf.addPage();
                    }
                }
                let blobPdf = pdf.output("blob");
                // blobPdf.lastModifiedDate = new Date();
                // blobPdf.name = "动态心电报告";
                // console.log(blobPdf);
                // let file = new File([blobPdf],"动态心电报告",{ type: "application/pdf"});
                // console.log(file);
                let url = URL.createObjectURL(blobPdf);
                console.log(url);
                loading.close();
                

                that.$confirm('PDF生成成功, 是否预览?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    window.open(url);
                }).catch(() => {
                    
                });


                // var string = pdf.output('datauristring');
                // var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
                // var x = window.open();
                // x.document.open();
                // x.document.write(embed);
                // x.document.close();


                // pdf.save(title)

            }
            // pdf.save(title);
            // let uri = pdf.output("datauristring");
            // console.log(uri);
            // return uri;

        }
    }
}