import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)


const state = {
  ecgFile:null,
  waveChartFile:null,
  beatTypeFile:null,
  pdf:null,
  sampleRage:250,
  sliceSize: 720000,  //分割的文件大小 1h = 60*60*200

  ecgRawArray: [], //ecg去除包头后文件
  ecgRawArrayLength: 0,
  ecgFiltArray: [], //服务器算法滤波后数据
  ecgFileArrayLength:0,
  ecgRIndexArray: [], //R波标记位置
  ecgRBeatTypeArray: [], // R波标记类型
  ecgHrtArray: [], //R波瞬时心率
  isRawEcgArray:true,  // true绘制原始ecg数据   false绘制算法滤波后ecg数据


}

const store = new Vuex.Store({
  state,
  mutations:{

    setEcgFile(state,ecgFile){
      state.ecgFile = ecgFile;
    },

    setSampleRate(state,sampleRate){
      state.sampleRage = sampleRate;
      state.sliceSize = sampleRate*60*60; //根据采样率计算一小时的数据点数
    },
    setEcgRawArray(state,ecgRawArray){
      state.ecgRawArray = ecgRawArray;
      state.ecgRawArrayLength = ecgRawArray.length;
      state.isRawEcgArray = true;
      console.log(`setEcgRawArray len = ${ecgRawArray.length}`);
    },
    setEcgFiltArray(state,ecgFiltArray){
      state.ecgFiltArray = ecgFiltArray;
      state.ecgFileArrayLength = ecgFiltArray.length;
      state.isRawEcgArray = false;
      console.log(`setEcgFiltArray len = ${ecgFiltArray.length}`);
    },
    setEcgRIndexArray(state,ecgRIndex){
      state.ecgRIndexArray = ecgRIndex;
    },
    setEcgRBeatTypeArray(state,rBeatType){
      state.ecgRBeatTypeArray = rBeatType;
    },
    setEcgHrtArray(state,hrtArray){
      state.ecgHrtArray = hrtArray;
    },
    setIsRawEcgArray(state,isRawEcgData){
      state.isRawEcgArray = isRawEcgData;
    },
    releaseArray(state){
      state.ecgRawArray = null;
      state.ecgFiltArray = null;
      state.ecgHrtArray.length = 0;
      state.ecgRBeatTypeArray.length = 0;
      state.ecgRIndexArray.length = 0;
      state.ecgRawArrayLength = 0;
      state.ecgFileArrayLength = 0;
    }
  }
})
export default store;
