import Vue from 'vue'
import VueRouter from 'vue-router'
import DragFileView from '@/components/DragFileView.vue'
import EcgView from '@/views/EcgView.vue'
import PreviewPdf from '@/views/PreviewPdf.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'DragFileView',
    component: DragFileView,
    meta: {
      title: "选取文件 | MinttiEcg"
    }
  },
  {
    path: '/EcgView',
    name: 'EcgView',
    component: EcgView,
    meta: {
      title: "查看ECG | MinttiEcg"
    }

  },
  {
    path: '/PreviewPdf',
    name: 'PreviewPdf',
    component: PreviewPdf,
    meta: {
      title: "查看ECG | MinttiEcg"
    }

  }
  
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
