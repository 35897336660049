import { install } from "element-ui";
import Vue from "vue";

export default {

  isUUID:function (uuid){
    let reg = new RegExp("\[a-f0-9]{8}(-\[a-f0-9]{4}){3}-\[a-f0-9]{12}","i");
    return reg.test(uuid);
  },


  calcuRealMv: function (point) {
    return (((point * 3.6) / 4096.0) * 1000) / 1100;
  },


  /**
   * 获取ecg文件多少小时，不满一小时以一小时计算
   * @param ecgArrayLength
   * @param sliceSize
   * @returns {number}
   */
  getTotalEcgHourCount(ecgArrayLength,sliceSize){
    if (ecgArrayLength <= sliceSize){
      return 1;
    }else {
      return  ecgArrayLength % sliceSize === 0 ? ecgArrayLength / sliceSize :
          Math.trunc(ecgArrayLength / sliceSize) + 1;
    }
  },

  /*将ecg文件按时长分割成多个数组进行绘图*/
  sliceEcgArray: function (ecgArray, sliceSize) {
    //计算分割次数
    let sliceCount = 1;
    let sliceArray = new Array(sliceCount);
    let ecgData = new Int16Array(ecgArray);
    if (ecgArray.length <= sliceSize) {
      sliceArray[0] = ecgArray;
      return sliceArray;
    } else {
      sliceCount = ecgArray.length % sliceSize === 0 ? ecgArray.length / sliceSize :
        Math.trunc(ecgArray.length / sliceSize) + 1;
      // console.log("sliceCount = " + sliceCount);
      sliceArray = new Array(sliceCount);
      // console.log(sliceArray);
      // console.log("sliceArray.length = " + sliceArray.length);
      for (let i = 0; i < sliceCount; i++) {
        if (i + 1 === sliceCount) {
          let arr = ecgData.slice(i * sliceSize);
          sliceArray[i] = arr;
        } else {

          let arr = ecgData.slice(i * sliceSize, i * sliceSize + sliceSize);
          sliceArray[i] = arr;
        }
      }
      // console.log("SliceArray = ");
      // console.log(sliceArray);
      return sliceArray;
    }
  },

  getFileNameSuffix: function (fileName) {
    return fileName.substr(fileName.lastIndexOf(".") + 1).toLowerCase();
  },
  formatSeconds: function (value) {
    // console.log("formatSeconds value = "+value)
    var theTime = parseInt(value); // 秒
    var theTime1 = 0; // 分
    var theTime2 = 0; // 小时
    if (theTime > 60) {
      theTime1 = parseInt(theTime / 60);
      theTime = parseInt(theTime % 60);
      if (theTime1 > 60) {
        theTime2 = parseInt(theTime1 / 60);
        theTime1 = parseInt(theTime1 % 60);
      }
    }

    var result = "" + parseInt(theTime); //秒
    if (10 > theTime > 0) {
      result = "0" + parseInt(theTime); //秒
    } else {
      result = "" + parseInt(theTime); //秒
    }

    if (10 > theTime1 > 0) {
      result = "0" + parseInt(theTime1) + ":" + result; //分，不足两位数，首位补充0，
    } else {
      result = "" + parseInt(theTime1) + ":" + result; //分
    }
    if (theTime2 > 0) {
      result = "" + parseInt(theTime2) + ":" + result; //时
    }
    return result;
  },
  formatSecondsZh: function (value) {
    var sec = parseInt(value); // 秒
    var min = 0; // 分
    var hour = 0; // 小时
    if (sec > 60) {
      min = parseInt(sec / 60);
      sec = parseInt(sec % 60);
      if (min > 60) {
        hour = parseInt(min / 60);
        min = parseInt(min % 60);
      }
    }

    var result = "";

    if (hour == 0 && min == 0) {
      result = sec + "秒";
    }
    if (hour == 0 && min != 0) {
      if (sec != 0) {
        result = min + "分" + sec + "秒";
      } else {
        result = min + "分钟";
      }

    }
    if (hour != 0) {
      if (min != 0) {
        result = hour + "小时" + min + "分钟"
      } else {
        result = hour + "小时"
      }

    }
    return result;
  },

  formatDate: function (time, format) {
    var date = new Date(time);

    var year = date.getFullYear(),
      month = date.getMonth() + 1,//月份是从0开始的
      day = date.getDate(),
      hour = date.getHours(),
      min = date.getMinutes(),
      sec = date.getSeconds();
    var preArr = Array.apply(null, Array(10)).map(function (elem, index) {
      return '0' + index;
    }); //开个长度为10的数组 格式为 00 01 02 03

    var newTime = format.replace(/YY/g, year)
      .replace(/MM/g, preArr[month] || month)
      .replace(/DD/g, preArr[day] || day)
      .replace(/hh/g, preArr[hour] || hour)
      .replace(/mm/g, preArr[min] || min)
      .replace(/ss/g, preArr[sec] || sec);

    return newTime;

  },

  textIsEmpty: function (text) {
    return typeof text == "undefined" || text == null || text === "";
  },

  install(Vue,options){
    Vue.prototype.bytes2Int = function(bytes) {
      return (bytes[0] & 0xFF) | (bytes[1] & 0xFF) << 8 | (bytes[2] & 0xFF) << 16 | (bytes[3] & 0xFF) << 24;
    }

    Vue.prototype.bytes2Short = function(bytes) {
      return (bytes[0] & 0xFF) | (bytes[1] & 0xFF) << 8 ;
    }
    Vue.prototype.byte2Short = function(byte0,byte1) {
      return (byte0 & 0xFF) | (byte1 & 0xFF) << 8 ;
    }

  }


};
