
const isDebug = false;
const baseURL = isDebug ? "http://192.168.0.26:8080/user-app/" : "http://manager.mintti.cn/user-app/";

export const  myUrl = {

    submitInfo:"http://taiyi_ecg.tjtzkt.com/submitInfo.aspx",
    getEcgPdf:"http://taiyi_ecg.tjtzkt.com/submitInfo.aspx",
    analysisLocalModeEcg:baseURL+"/analysisLocalModeEcg",

};