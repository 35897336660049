<template>
  <div class="home">

<!--    <div class="home-header">-->

<!--      <div class="div-title-wrapper">-->
<!--        MinttiEcg-->
<!--      </div>-->

<!--    </div>-->

    <router-view/>
 
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  }
}
</script>
<style scoped>
.home {
  width:100%;
  height:100%;
  
}
/*.home-header {*/
/*  width: 100%;*/
/*  height: 100px;*/
/*  background: #fff;*/
/*  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);*/
/*}*/
</style>