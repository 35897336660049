<template>
  <div class="ecg-result-view">
    <div class="ecg-view-title">动态心电报告</div>
    <div class="subject-title">受检者信息</div>
    <div class="subject-container">
      <el-row type="flex" justify="space-around">
        <el-col :span="6">
          <span>姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名:&nbsp;&nbsp;{{ ecgResult.userName }}</span>
        </el-col>
        <el-col :span="6"><span></span></el-col>
        <el-col :span="6"><span></span></el-col>
      </el-row>
      <el-row type="flex" justify="space-around">
        <el-col :span="6">
          <span>开始时间:&nbsp;&nbsp;{{ ecgResult.startTime }}</span>
        </el-col>
        <el-col :span="6">
          <span>结束时间:&nbsp;&nbsp;{{ ecgResult.endTime }}</span>
        </el-col>
        <el-col :span="6"><span>记录时长:&nbsp;&nbsp;{{ecgResult.ecgDuration}}</span></el-col>
      </el-row>
    </div>
    <div class="heart-rate-title">心率</div>
    <div class="heart-rate-container">
      <el-row type="flex" justify="space-around">
        <el-col :span="5">
          <span>总心搏数:&nbsp;&nbsp;{{ ecgResult.beatNum }} 次</span>
        </el-col>
        <el-col :span="5">
          <span>长间歇总数:&nbsp;&nbsp;{{ ecgResult.stopBeatsNum }} 次</span>
        </el-col>
        <el-col :span="5">
          <span>最快心率:&nbsp;&nbsp;{{ ecgResult.highestHeartRate }} BPM</span>
        </el-col>
        <el-col :span="5"><span>发生于:&nbsp;&nbsp;{{ecgResult.highestHRIndexTime}}</span></el-col>
      </el-row>

      <el-row type="flex" justify="space-around">
        <el-col :span="5">
          <span>平均心率:&nbsp;&nbsp;{{ ecgResult.avrHeartRate }} BPM</span>
        </el-col>
        <el-col :span="5">
          <span>平均呼吸率:&nbsp;&nbsp;{{ ecgResult.avrRespRate }} BPM</span>
        </el-col>
        <el-col :span="5">
          <span>最慢心率:&nbsp;&nbsp;{{ ecgResult.lowestHeartRate }} BPM</span>
        </el-col>
        <el-col :span="5"><span>发生于:&nbsp;&nbsp;{{ecgResult.lowestHRIndexTime}}</span></el-col>
      </el-row>

      <el-row type="flex" justify="space-around">
        <el-col :span="5">
          <span>平均RR间期:&nbsp;&nbsp;{{ ecgResult.avrRR }} ms</span>
        </el-col>
        <el-col :span="5">
          <span>平均QRS间期:&nbsp;&nbsp;{{ ecgResult.avrQRS }} ms</span>
        </el-col>
        <el-col :span="5">
          <span>平均QT间期:&nbsp;&nbsp;{{ ecgResult.avrQT }} ms</span>
        </el-col>
        <el-col :span="5">
          <span>平均P波时限:&nbsp;&nbsp;{{ ecgResult.avrP }} ms</span>
        </el-col>
      </el-row>

      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="5">
          <div class="grid-content bg-purple">心动过速:&nbsp;&nbsp;{{ ecgResult.TachNum }} 次</div>
        </el-col>
        <el-col :span="5">
          <div>心动过缓:&nbsp;&nbsp;{{ ecgResult.BraNum }} 次</div>
        </el-col>
        <el-col :span="5">
          <div>房性早搏:&nbsp;&nbsp;{{ ecgResult.APBNum }} 次</div>
        </el-col>
        <el-col :span="5">
          <div>室性早搏:&nbsp;&nbsp;{{ ecgResult.VPCNum }} 次</div>
        </el-col>
      </el-row>
    </div>
    <div class="hrv-title">HRV 结果</div>
    <div class="hrv-container">
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="5">
          <div class="grid-content bg-purple">SDNN:&nbsp;&nbsp;{{ parseFloat(ecgResult.SDNN.toFixed(2)) }} ms</div>
        </el-col>
        <el-col :span="5">
          <div>RMSSD:&nbsp;&nbsp;{{ parseFloat(ecgResult.RMSSD.toFixed(2))}} ms</div>
        </el-col>
        <el-col :span="5">
          <div>SDANN:&nbsp;&nbsp;{{ parseFloat(ecgResult.SDANN.toFixed(2)) }} ms</div>
        </el-col>
        <el-col :span="5">
          <div>SDNNIndex:&nbsp;&nbsp;{{ parseFloat(ecgResult.SDNNIndex.toFixed(2)) }} ms</div>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="5">
          <div>NN50:&nbsp;&nbsp;{{ parseFloat(ecgResult.NN50.toFixed(2)) }} </div>
        </el-col>
        <el-col :span="5">
          <div>pNN50:&nbsp;&nbsp;{{ parseFloat(ecgResult.pNN50.toFixed(2))}} </div>
        </el-col>
        <el-col :span="5">
          <div>TP:&nbsp;&nbsp;{{ parseFloat(ecgResult.TP.toFixed(2)) }} </div>
        </el-col>
        <el-col :span="5">
          <div>LF:&nbsp;&nbsp;{{ parseFloat(ecgResult.LF.toFixed(2)) }} </div>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="5">
          <div>HF:&nbsp;&nbsp;{{ parseFloat(ecgResult.HF.toFixed(2)) }} </div>
        </el-col>
        <el-col :span="5">
          <div>VLF:&nbsp;&nbsp;{{ parseFloat(ecgResult.VLF.toFixed(2))}} </div>
        </el-col>
        <el-col :span="5">
          <div>LF_HF_Ratio:&nbsp;&nbsp;{{ parseFloat(ecgResult.LF_HF_Ratio.toFixed(2)) }} </div>
        </el-col>
        <el-col :span="5">
          <div> </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "EcgAlgoResultView",
  data() {
    return {
      
    };
  },
  props: {
    ecgResult: {
      type:Object,
      default:{
        TachNum: 0,
        APBNum: 0,
        BraNum: 0,
        avrQRS: 0,
        avrQT: 0,
        avrRespRate: 0,
        avrRR: 0,
        stopBeatsNum: 0,
        lowestHRIndex: 0,
        lowestHeartRate: 0,
        highestHeartRate: 0,
        beatNum: 0,
        VPCNum: 0,
        avrHeartRate: 0,
        highestHRIndex: 0,
        avrP: 0,
        //文件记录时长
        ecgDuration:"",
        //心率最大值位置
        lowestHRIndexTime:"",
        //心率最小值位置
        highestHRIndexTime:"",
        //comment
        localStorageStartTime: "",
        sampleTime: 0,
        startTime: "",
        endTime:"",
        userName: "",

        //Hrv 结果
        //HRV
        SDNN: 0,
        RMSSD: 0,
        SDANN: 0,
        SDNNIndex: 0,
        NN50: 0,
        pNN50: 0,
        TP: 0,
        LF: 0,
        HF: 0,
        VLF: 0,
        LF_HF_Ratio: 0,


      }
    },
    
  },
  // created() {
  //   // this.ecgResult = this.$store.state.ecgAlgoResult;
  //   console.log("created");
  //   // console.log(this.ecgResult);
  // },
  // beforeMount() {
  //   console.log("beforeMount");
  // },
  // mounted() {
  //   console.log("mounted");
  // },
  // updated() {
  //   console.log("updated");
  //
  // }
};
</script>
<style scoped></style>
<style lang="less" scoped>
.ecg-result-view {
  width: 100%;
  overflow: hidden;

  .ecg-view-title {
    font-size: 50px;
    text-align: center;
    padding: 20px;
    margin: 20px 0;
  }
  .subject-title,
  .heart-rate-title,
  .hrv-title {
    text-align: center;
    background: #ffe6e6;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    margin: 20px 0;
  }
  .subject-container {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
  }
  .heart-rate-container,
  .hrv-container {
    margin-top: 20px;
    font-size: 18px;
  }
  
  .el-row {
    margin-bottom: 20px;
  }
  .el-row:last-child {
    margin-bottom: 0;
  }
  .el-col {
    // border: 1px solid skyblue;
    // text-align: center;
  }
}
</style>
