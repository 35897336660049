<template>
  <div class="drag-container" ref="dragfile"
       v-loading="isShowLoading"
       element-loading-text="打开文件中..."
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <input type="file" id="getFile" style="display: none" ref="getFile" @change="loadEcgFile()" accept=".ecg , .ann"/>
    <div class="drag-wrapper" ref="dragFile" :class="{ draghover: isHover }">
      <div class="drag">
        <div class="el-icon-upload" :class="{ elIconUploadHover: isHover }"></div>
        <div class="click-upload-container">将文件拖到此处 , 或<span class="click-upload-text">点击上传</span></div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: "DragFileView",
  data() {
    return {
      isHover: false,
      ecgArray: [],
      uuid: "",
      loadingInstance: null,
      ecgFile: null,
      isShowLoading: false,
    };
  },
  methods: {
    handleFile(file) {
      // this.loadingInstance.close();
      this.isShowLoading = false;
      if (file) {
        // if (this.$utils.getFileNameSuffix(file.name) != "ecg") {
        //   console.log("请打开ecg文件");
        //   this.$message("请打开ecg文件", "warning");
        //   return;
        // }
        // let fileReader = new FileReader();
        // fileReader.onload = (e) => {
        //   let result = e.target.result;
        //   console.log(result);

        // };
        // fileReader.readAsArrayBuffer(file);

        // if (file.name === "wave_chars.ann") {
        //   this.$store.state.waveChartFile = file;
        // } else if (file.name === "beat_type.ann") {
        //   this.$store.state.beatTypeFile = file;
        // } else if (this.$utils.getFileNameSuffix(file.name) == "ecg") {
        //   this.$store.state.ecgFile = file;
        //   this.$router.push({
        //     path: "/EcgView",
        //   });
        // }
        if (this.$utils.getFileNameSuffix(file.name) === "ecg") {
          this.$store.commit("setEcgFile",file);
          this.$router.push({
            path: "/EcgView",
          });
        }

      }
    },
    loadEcgFile() {
      this.isShowLoading = true;
      // let that = this;
      // setTimeout(function () {
      //   let file = that.$refs.getFile.files[0];
      //   that.handleFile(file);
      // },1000);
      let file = this.$refs.getFile.files[0];
      this.handleFile(file);
      // let fileReader = new FileReader();
      // fileReader.onload = (e) => {
      //   let result = e.target.result;
      //   that.ecgArray = new Int16Array(result);
      //   console.log(that.ecgArray.length);
      // };
      // fileReader.readAsArrayBuffer(file);
    },

  },
  mounted() {
    let drag = this.$refs.dragFile;
    let getFile = this.$refs.getFile;
    let that = this;
    // console.log(drag);

    drag.addEventListener("click", function (e) {
      console.log(e);
      getFile.click();
    });
    drag.addEventListener(
        "drop",
        function (e) {
          e.preventDefault();
          e.stopPropagation();
          let files = e.dataTransfer.files;
          // let fileList = e.dataTransfer.files; //获取文件对象
          // that.uploadFile(files[0]);
          that.handleFile(files[0]);
          that.isHover = false;
        },
        false
    );
    drag.addEventListener(
        "dragenter",
        function (e) {
          e.preventDefault();
          e.stopPropagation();
          that.isHover = true;
          // console.log('enter');
        },
        false
    );

    drag.addEventListener(
        "dragover",
        function (e) {
          e.preventDefault();
          e.stopPropagation();
          // e.dataTransfer.dropEffect = "copy";
        },
        false
    );

    drag.addEventListener(
        "dragleave",
        function (e) {
          e.preventDefault();
          e.stopPropagation();
          that.isHover = false;
          // console.log('leave');
        },
        false
    );
  },
  destroyed() {
    this.isShowLoading = false;
  }
};
</script>
<style scoped>
.drag-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drag-wrapper {
  width: 800px;
  height: 300px;
  border: 1px dashed gray;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drag-wrapper:hover {
  border: 1px dashed #409eff;
}

.draghover {
  border: 1px dashed #409eff;
}

.drag {
  margin-top: -30px;
  z-index: -1;
}

.el-icon-upload {
  font-size: 150px;
  color: #c0c4cc;
  margin-left: 50%;
  transform: translateX(-75px);
  text-align: center;
}

.elIconUploadHover {
  color: #409eff;
}

.click-upload-container {
  text-align: center;
}

.click-upload-text {
  color: #409eff;
}
</style>
