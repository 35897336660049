import { render, staticRenderFns } from "./EcgAlgoResultView.vue?vue&type=template&id=cab9ca8c&scoped=true&"
import script from "./EcgAlgoResultView.vue?vue&type=script&lang=js&"
export * from "./EcgAlgoResultView.vue?vue&type=script&lang=js&"
import style1 from "./EcgAlgoResultView.vue?vue&type=style&index=1&id=cab9ca8c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cab9ca8c",
  null
  
)

export default component.exports