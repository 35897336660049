import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue'
import less from 'less'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import Vuex from 'vuex'
import utils from '@/util/utils.js'
import * as echarts from 'echarts'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import htmlToPdf from "./util/htmlToPdf.js";
import {myUrl} from "./util/UrlConfig.js"
// if (Number.parseInt === undefined) Number.parseInt = window.parseInt;
// if (Number.parseFloat === undefined) Number.parseFloat = window.parseFloat;
import store from './store'


Vue.config.productionTip = false
let isDebug = false;
// axios.defaults.baseURL = isDebug ? "http://192.168.0.26:8080/user-app/" : "http://manager.mintti.cn/user-app/"
Vue.prototype.$axios = axios    //全局注册，使用方法为:this.$axios


Vue.prototype.$utils = utils
// Vue.prototype.$echarts = echarts
Vue.prototype.$myUrl = myUrl;
Vue.use(ElementUI)
Vue.use(less)
Vue.use(Vuex)
Vue.use(htmlToPdf)
Vue.use(utils)

// const ftp = new jsftp({
//   host:"8.136.96.202",
//   port:"21",
//   user:"mna",
//   pass:"mna"
// });
// Vue.use(jsftp)

router.beforeEach((to,from,next) =>{
  if(to.meta.title){
    document.title = to.meta.title
  }
  next();
})

//实现个EventBus用于组件间通信
let eventBus = new Vue();
Vue.prototype.$eventBus = eventBus;


let vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default vue;