<template>
  <div>
    <div class="echart-container">
      <!-- <div id="echart-wrapper" :class="canvasRef"></div> -->
      <div class="echart-wrapper"  :id="ecgViewId" ></div>
      <el-slider v-model="currentPosition" :min="sliderMin" :max="sliderMax" :format-tooltip="formatTooltip"
                 @input="sliderInput" :show-tooltip="false"></el-slider>
      <div class="progress-container">
        <div class="current-progress">{{ currentProgress }}</div>

        <div class="total-progress">{{ totalProgress }}</div>
      </div>
    </div>
    <!-- <el-slider v-model="currentPosition"/>
    <el-slider v-model="currentPosition"></el-slider> -->
  </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  name: "EcgChartView",
  data() {
    return {
      // ecgArray: null,
      screenSize:1600,  //一屏绘制多少秒的数据  1600 = 200*8
      //sampleRate:200,
      ecgGain:1,  //增益
      // myChart: null,
      ecgTxts: null,
      loadingInstance: null,
      currentProgress: "00:00",
      totalProgress: "00:00",
      showMinorSplitLine: false,
      showSplitLine: false,
      //maxDataSize: 0, //最大绘制一小时数据 200*60*60
      canvasRef: "ecgChartView",
      currentPosition: 0,
      sliderMin: 0,
      sliderMax: 100,
      rMarkDatas: [],
      ecgArray: [],
      totalIndex: 0,
      maxDataSize: 0,
      rIndexArray: [],
      rBeatTypeArray: [],
      hrtArray :[],
      sampleRate: 200,
      isRawEcg: true,
      chartDom: null,
    };
  },
  props: {
    ecgViewId:"",
  },


  created() {
    console.log("EcgChartView created")
    // this.maxDataSize = this.$store.state.sliceSize;
    // this.rIndexArray = this.store.state.ecgRIndexArray;
    // this.sampleRate = this.$store.state.sampleRage;
    // this.rBeatTypeArray = this.$store.state.ecgRBeatTypeArray;
    // this.hrtArray = this.$store.state.ecgHrtArray;
  },

  mounted() {

    console.log("EcgChartView mounted = "+this.ecgViewId);
    this.chartDom = document.getElementById(this.ecgViewId);
  },

  beforeDestroy() {

    this.ecgArray = [];
    this.rMarkDatas.length = 0;
    this.rIndexArray.length = 0;
    this.rBeatTypeArray.length = 0;
    this.hrtArray.length = 0;

    // console.log(`${this.ecgViewId} beforeDestroy chartDom = ${this.chartDom} echarts = ${echarts} `);
    let myChart = echarts.getInstanceByDom(this.chartDom);
    if (myChart ){
      myChart.clear();
      myChart.dispose();
    }
    console.log(`${this.ecgViewId} beforeDestroy chartDom = ${this.chartDom} echarts = ${echarts} `);
  },

  destroyed() {

    console.log(`${this.ecgViewId} destroyed`);
  },


  methods: {

    loadEcgData() {
      this.maxDataSize = this.$store.state.sliceSize;
      this.rIndexArray = this.$store.state.ecgRIndexArray;
      this.sampleRate = this.$store.state.sampleRage;
      this.rBeatTypeArray = this.$store.state.ecgRBeatTypeArray;
      this.hrtArray = this.$store.state.ecgHrtArray;
      this.isRawEcg = this.$store.state.isRawEcgArray;
      this.screenSize = this.sampleRate * 8;
    },

    asyncRefreshChartView(ecgData,index,callback){
      let that = this;
      new Promise(function (resolve, reject){
        that.refreshChartView(ecgData,index);
        resolve("done");
      }).then(function (){
        console.log(`EcgChartView ${index} 刷新完成`);
        callback();
      })
    },

    refreshChartView(ecgData,index){
      // this.loadEcgData();
      // if (this.myChart == null){
      //   let chart = document.getElementById(this.ecgViewId);
      //   this.myChart = this.$echarts.init(chart, null, {renderer: "canvas"});
      //   this.loadEcgData();
      // }else {
      //   this.myChart.clear();
      //
      // }
      this.loadEcgData();
      this.totalIndex = index;
      this.ecgArray = ecgData;
      this.sliderMax = this.ecgArray.length;
      this.currentPosition = 0;
      this.currentProgress = this.$utils.formatSeconds((this.totalIndex * this.maxDataSize) / this.sampleRate);
      this.totalProgress = this.$utils.formatSeconds((this.totalIndex * this.maxDataSize + this.ecgArray.length) / this.sampleRate);

      console.log("refreshChartView ecgArray.length = " +this.ecgArray.length+" totalIndex = "+this.totalIndex +" this.maxDataSize = "+this.maxDataSize);
      // console.log(this.rIndexArray);
      // console.log(this.rBeatTypeArray);
      // console.log(this.hrtArray);
      this.rMarkDatas.length = 0;
      for (let i = 0,len = this.rIndexArray.length; i < len; i++) {
        if ((this.rIndexArray[i] > this.totalIndex * this.maxDataSize)
            && (this.rIndexArray[i] < (this.totalIndex * this.maxDataSize + this.ecgArray.length))) {
          this.rMarkDatas.push({
            name: this.rIndexArray[i].toString(),
            xAxis: this.rIndexArray[i] - this.totalIndex * this.maxDataSize,
            yAxis: 2.8,
          });
        }
      }

      this.drwaChart();

    },

    drwaChart() {
      let yArray = [];
      let xArray = [];
      let realMv = 0;
      let data = [];
      for (let i = 0,len = this.ecgArray.length; i < len; i++) {
        realMv = this.isRawEcg? this.$utils.calcuRealMv(this.ecgArray[i]) - 1.636 : this.$utils.calcuRealMv(this.ecgArray[i])
        yArray[i] = this.ecgGain*(realMv)+1.75;
      }
      for (let i = 0,len = this.ecgArray.length; i < len; i++) {
        xArray[i] = i;
        data[i] = [i, yArray[i]];
      }

      this.setOption(xArray, data);
    },

    setOption(xArray, data) {
      let that = this;
      let myChart = null;

      myChart = echarts.getInstanceByDom(this.chartDom);
      if (myChart === null || myChart === undefined){
        myChart = echarts.init(this.chartDom, null, {renderer: "canvas"});
      }else {
        myChart.clear();
      }
      let options = {
        grid: {
          width: "1280px",
          height: "224px",
          left: "50px",
          top: "0",
          right: "50px",
          show: true,
        },
        xAxis: [
          {
            type: "value",
            // data: xArray,
            Z: 5,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              //轴线
              show: false,
            },
            axisLabel: {
              show: false,
            },
            // minInterval:1,
            min: 0,
            max: xArray.length >= that.screenSize ? xArray.length : that.screenSize,
            // interval: 1,
            // minorTick: {
            //   show: true,
            // },
            // minorSplitLine: {
            //   show: true
            // },
            axisPointer: {
              type: "line",
              show: false,
              // label: {
              //   formatter: function (params) {
              //     // console.log(params);
              //     return that.$utils.formatSeconds(params.value / 200);
              //   },
              // },
            },
          },
          // {
          //   min: 0,
          //   max: xArray.length >= 1280 ? xArray.length : 1280,
          //   interval: 40,
          //   Z: 1,
          //   splitLine: {
          //     show: that.showSplitLine,
          //     lineStyle: {
          //       color: "#ffbebe",
          //       width: 2,
          //     },
          //   },
          //   //是否显示坐标轴线
          //   axisLine: {
          //     show: false,
          //   },
          //   //是否显示坐标轴刻度
          //   axisTick: {
          //     show: false,
          //   },
          //   //是否显示坐标刻度文字
          //   axisLabel: {
          //     show: false,
          //   },
          //   //次刻度线
          //   minorTick: {
          //     show: false,
          //     splitNumber: 5,
          //     length: 0,
          //     lineStyle: {
          //       color: "#ffbebe",
          //     },
          //   },
          //   minorSplitLine: {
          //     show: that.showMinorSplitLine,
          //     lineStyle: {
          //       color: "#ffbebe",
          //       width: 1,
          //     },
          //   },
          // },
        ],
        yAxis: {
          name: "ecg",
          type: "value",
          Z: 5,
          min: 0,
          max: 3.5,
          // interval: 0.66,
          splitLine: {
            show: this.showSplitLine,
            lineStyle: {
              color: "#ffbebe",
              width: 2,
            },
          },
          //是否显示坐标轴线
          axisLine: {
            show: false,
          },
          //是否显示坐标轴刻度
          axisTick: {
            show: false,
          },
          //是否显示坐标刻度文字
          axisLabel: {
            show: false,
          },
          //次刻度线
          minorTick: {
            show: false,
            splitNumber: 5,
            length: 0,
            lineStyle: {
              color: "#ffbebe",
            },
          },
          minorSplitLine: {
            show: this.showMinorSplitLine,
            lineStyle: {
              color: "#ffbebe",
              width: 1,
            },
          },
        },

        dataZoom: [
          {
            // 这个dataZoom组件，默认控制x轴。
            type: "inside", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
            startValue: 0,
            endValue: that.screenSize,
            // minValueSpan: 400,
            filterMode: "filter",
            xAxisIndex: [0],
            zoomLock: true, //不可缩放
            brushSelect: false,
          },
        ],
        series: [
          {
            type: "line",
            data: data,
            showSymbol: false,
            animation: false,
            sampling: "lttb",
            lineStyle: {
              color: "#a26810",
              width: 2,
            },
            emphasis: {
              lineStyle: {
                width: 2,
              },
            },
            markPoint: {
              symbol: "pin",
              symbolSize: 0,
              animation: false,
              data: that.rMarkDatas,

              label: {
                show: true,
                position: "top",
                formatter: function (param) {
                  // console.log(param);
                  let index = that.rIndexArray.indexOf(Number.parseInt(param.name));
                  // console.log(index)
                  let beatType = null;
                  let hrt = null;
                  let patt = new RegExp("[SVFANTM]");
                  if (index !== -1 ){
                    beatType = that.rBeatTypeArray[index];
                    hrt = that.hrtArray[index];
                  }
                  if (beatType != null && patt.test(beatType)){
                    return `${beatType}\n${hrt}`;
                  }

                },
                fontSize: 16,
                lineHeight: 18,
              },

              // symbolSize:0,
            },
          },
          // {
          //   type: "scatter",
          //   data: rIndexDatas,
          //   animation:false,
          // }
        ],
      };
      let dataLen = xArray.length;
      myChart.on("finished", function () {
        if (that.loadingInstance) {
          that.loadingInstance.close();
        }

        // console.log(that.totalProgress);
        // that.myChart.hideLoading();
      });

      myChart.setOption(options);
      myChart.on("dataZoom", function (params) {
        // 控制台打印数据的名称
        // console.log(params);
        if (params.batch) {
          let start = (params.batch[0].start / 100) * dataLen;
          // that.currentProgress = that.$utils.formatSeconds((that.maxDataSize * that.totalIndex + start) / 200);
          that.currentPosition = start;
          // console.log(`start = ${start} end = ${end}   ${end - start}`);
        } else {
          let start = (params.start / 100) * dataLen;
          // that.currentProgress = that.$utils.formatSeconds((that.maxDataSize * that.totalIndex + start) / 200);
          that.currentPosition = start;
        }
      });

      myChart.on("click", function (params) {
        console.log(params);
      });
    },
    formatTooltip(val) {
      return this.$utils.formatSeconds((this.maxDataSize * this.totalIndex + val) / this.sampleRate);
    },
    sliderInput(value) {
      // console.log("sliderInput value: " + value);
      this.currentProgress = this.$utils.formatSeconds((this.maxDataSize * this.totalIndex + value) / this.sampleRate);

      let myChart = echarts.getInstanceByDom(this.chartDom);
      if (myChart){
        myChart.dispatchAction({
          type: "dataZoom",
          // 开始位置的数值
          startValue: value,
          // 结束位置的数值
          endValue: value + this.screenSize,
        });
      }

    },
  },
};
</script>
<style scoped>
.echart-container {
  width: 100%;
  height: 294px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* border: 1px solid skyblue; */
  padding-bottom: 10px;
  padding-top: 20px;
  margin: 0 auto;
  /* background-color: rgba(229, 235, 221, 0.486); */
}

.echart-wrapper {
  width: 1380px;
  /*width: 100%;*/
  height: 224px;
  /* border: 1px solid black; */
  margin-top: 10px;
  background: url("../assets/ecg_bg_8s.png") no-repeat 50px 0;
  /*border: 1px solid skyblue;*/

}
.echart-wrapper:hover {
  cursor: pointer;
}
.progress-container {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid rosybrown; */
}

.el-slider {
  width: 1280px;
  /* border: 1px solid skyblue; */
  padding: 0;
}
</style>
