<template>
  <div class="ecg-view"
       v-loading.fullscreen.lock="isShowLoading"
       element-loading-text="数据加载中..."
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div id="ecg-view-container">
      <EcgAlgoResultView :ecg-result="ecgAlgoResult"/>
      <div class="ecg-view-title">ECG 波形图</div>
      <div class="ecg-gain-speed">25mm/s &nbsp;&nbsp;&nbsp;&nbsp;10mm/mv</div>
      <div class="ecg-chart-view-container" id="ecgbox">
        <PagingEcgChartView ref="paging-ecg-view"/>
        <!--        <EcgChartView v-for="(item, index) in ecgArrayList"-->
        <!--                      :key="index" :ecgArray="item"-->
        <!--                      :totalIndex="index" :rIndexArray="rIndexArray"-->
        <!--                      :rBeatTypeArray="rBeatTypeArray"-->
        <!--                      :hrtArray="hrtArray"/>-->
<!--        <PagingEcgChartView/>-->
      </div>
    </div>

    <el-button type="primary" class="bt-generate-pdf" @click="onGetPdf()" v-show="isShowGetPdf">购买PDF报告</el-button>
    <el-button type="primary" class="bt-back" @click="onClose" icon="el-icon-arrow-left">返回</el-button>
    <!--    <i class="el-icon-back" @click="onClose()"/>-->
  </div>
</template>
<script>
import EcgChartView from "@/components/EcgChartView.vue";
import EcgAlgoResultView from "@/components/EcgAlgoResultView.vue";
import Vue from "vue";
import iconv from "iconv-lite";
import moment from "moment";
import PagingEcgChartView from "@/components/PagingEcgChartView";

export default {
  name: "EcgView",
  components: {
    PagingEcgChartView,
    EcgChartView,
    EcgAlgoResultView,
  },
  data() {
    return {
      //用于下载算法文件
      http: this.$axios.create({
        // baseURL: "/ecgFile",
        responseType: "arraybuffer",
      }),
      ecgHttp: this.$axios.create({
        baseURL: "/ecg"
      }),
      isLoading: false,
      uuid: "",
      ecgFile: null,
      waveChartFile: null,
      beatTypeFile: null,
      // ecgArray: [],
      waveChartArray: [],
      // rIndexArray: [], //R波标记位置
      // rBeatTypeArray: [], //R波标记类型
      // hrtArray: [],      //R波瞬时心率
      ecgArrayList: [],
      filtEcgArray: [],//服务器算法滤波后文件
      ecgSliceSize: 0,
      ftpSuccess: 0,
      sampleRate: 250,
      ecgAlgoResult: {
        filtEcgUrl: "",
        beatTypeUrl: "",
        waveCharsUrl: "",
        TachNum: 0,
        APBNum: 0,
        BraNum: 0,
        avrQRS: 0,
        avrQT: 0,
        avrRespRate: 0,
        avrRR: 0,
        stopBeatsNum: 0,
        lowestHRIndex: 0,
        lowestHeartRate: 0,
        highestHeartRate: 0,
        beatNum: 0,
        comment: {
          localStorageStartTime: "",
          sampleTime: 0,
          startTime: "",
          userName: "",
        },
        VPCNum: 0,
        avrHeartRate: 0,
        highestHRIndex: 0,
        avrP: 0,
        //文件记录时长
        ecgDuration: "",
        //心率最大值位置
        lowestHRIndexTime: "",
        //心率最小值位置
        highestHRIndexTime: "",
        //comment
        localStorageStartTime: "",
        sampleTime: 0,
        startTime: "",
        endTime: "",
        userName: "",
        //HRV
        SDNN: 0,
        RMSSD: 0,
        SDANN: 0,
        SDNNIndex: 0,
        NN50: 0,
        pNN50: 0,
        TP: 0,
        LF: 0,
        HF: 0,
        VLF: 0,
        LF_HF_Ratio: 0,
      },
      isShowGetPdf: false,
      loadingInstance: null,
    };
  },
  computed: {
    isShowLoading:{
      get(){
        return this.isLoading;
      },
      set(value) {
        this.isLoading = value;
      }
    }
  },
  methods: {
    /**
     * 打开本地ecg文件
     * @param file
     */
    openFile(file) {
      let that = this;
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        if (that.loadingInstance) {
          that.loadingInstance.close();
        }
        let result = e.target.result;
        if (that.$utils.getFileNameSuffix(file.name) === "ecg") {

          console.log("+++++++++++++++++++++++++++解析文件+++++++++++++++++++++++++++++++++++");
          let tempArr = new Uint8Array(result.slice(0, 2));
          let infoLen = this.byte2Short(tempArr[0], tempArr[1]);
          console.log(` result[0] = ${tempArr[0]} result[1] = ${tempArr[1]} infoLen = ${infoLen}`);
          let infoArr = new Uint8Array(result.slice(0, infoLen));

          let uuidStr = String.fromCharCode.apply(null, new Uint8Array(infoArr.slice(2, 38)));
          let sampleRate = this.byte2Short(infoArr[38], infoArr[39]);
          let time = String.fromCharCode.apply(null, new Uint8Array(infoArr.slice(40, 56)));
          // let name = String.fromCharCode.apply(null, new Uint8Array(infoArr.slice(56)));
          let name = iconv.decode(new Uint8Array(infoArr.slice(56)), 'gb2312');
          console.log(`file uuid = ${uuidStr} sampleRate = ${sampleRate} time = ${time} name = ${name}`);
          console.log("+++++++++++++++++++++++++++结束解析+++++++++++++++++++++++++++++++++++")
          if (this.$utils.isUUID(uuidStr)) {
            that.uuid = uuidStr;
            // that.$store.state.sampleRage = sampleRate;
            that.$store.commit("setSampleRate",sampleRate);
            that.sampleRage = sampleRate;
            that.ecgSliceSize = that.$store.state.sliceSize;
            that.ecgAlgoResult.userName = name;
            that.ecgAlgoResult.startTime = moment(time, "YYYY-MM-DD-HH-mm").format("YYYY-MM-DD HH:mm:ss");
            console.log(that.ecgAlgoResult.startTime)

          }
          // that.uuid = String.fromCharCode.apply(null, new Uint8Array(result.slice(0, 36)));
          // let ecgRawLen = result
          let ecgRawLen = 0 ;
          let ecgData = [];
          console.log(`file uuid = ${that.uuid}`);
          if (that.$utils.textIsEmpty(that.uuid)) {

            ecgRawLen = result.byteLength ;
            if (ecgRawLen %2 === 0){
              ecgData = result;
            }else {
              ecgData = result.slice(1);
            }
            that.$store.commit("setEcgRawArray",new Int16Array(ecgData)) ;
            that.$store.commit("setIsRawEcgArray",false);
          } else {
            ecgRawLen = result.byteLength - infoLen;
            if (ecgRawLen %2 === 0){
              ecgData = result.slice(infoLen);
            }else {
              ecgData = result.slice(infoLen,ecgRawLen-1);
            }
            let ecgArray = new Int16Array(ecgData);
            that.$store.commit("setEcgRawArray",ecgArray) ;
            that.$store.commit("setIsRawEcgArray",true);
            // console.log(result);
            console.log(` result.length = ${result.length} ecgRwaLen = ${ecgRawLen} ecgDataLen = ${ecgData.length}`);
          }
          // console.log(that.ecgArray.length);
          // let sliceArr = that.$utils.sliceEcgArray(that.ecgArray, that.ecgSliceSize);
          // // console.log("sliceArr len = " + sliceArr.length);
          // for (let i = 0; i < sliceArr.length; i++) {
          //   that.ecgArrayList.push(sliceArr[i]);
          // }
          that.setLocalEcgResult();
          //打开本地文件后上传文件至服务器
          if (this.$utils.textIsEmpty(that.uuid) === false) {
            console.log("open file  -> start upload ecg file");
            this.uploadEcgFile(this.ecgFile);
          } else {
            that.$alert("无法获取算法结果", "文件ID缺失", {
              closeOnClickModal: true,
            });
            that.$store.commit("setIsRawEcgArray",true);
            that.addEcgChartView();
          }

        }

      };
      fileReader.onerror = (e) => {
        console.log("fileReader.onerror");
        console.log(e);
        if (that.loadingInstance) {
          that.loadingInstance.close();
        }
      };
      fileReader.readAsArrayBuffer(file);
    },

    uploadEcgFile(file) {
      let param = new FormData();
      // param.append("ecgFile", "../assets/a11faa31-c52d-41a4-86a0-6aed8f80131e.ecg");
      // let stream = this.$fs.createReadStream("../assets/a11faa31-c52d-41a4-86a0-6aed8f80131e.ecg");
      param.append("ecgFile", file);
      console.log("start upload ecg file");
      let config = {
        headers: {"Content-Type": "multipart/form-data"},
      };
      let that = this;
      this.$axios
          // this.ecgHttp
          .post(this.$myUrl.analysisLocalModeEcg, param, config)
          .then(function (res) {
            let result = res.data;
            console.log(res);
            console.log(result);
            if (result.code === 200) {
              console.log("uploadEcgFile = " + JSON.stringify(result.data));
              // that.$store.state.ecgAlgoResult = result.data;
              that.setEcgResult(result.data);
              if (result.data.ftpSuccess != null && result.data.ftpSuccess === 1 && that.sampleRage === 250) {
                that.uploadJsonInfo();
              }
              that.downloadFiltEcgFile();
              // that.$set(that.ecgAlgoResult,'comment',result.data.comment)
              return;
            } else if (result.code === 224) {
              that.$alert("未找到受检者信息", "解析错误", {
                closeOnClickModal: true,
              });
              that.isLoading = false;
            } else {
              that.$alert(JSON.stringify(result), "解析错误");
              that.isLoading = false;
            }
            that.$store.commit("setIsRawEcgArray",true);
            that.addEcgChartView();
          })
          .catch(function (err) {
            console.log(err);
            that.$alert(JSON.stringify(err), "网络错误");
            that.isLoading = false;
            that.$store.commit("setIsRawEcgArray",true);
            that.addEcgChartView();
          });
    },
    setEcgResult(cloudEcgResult) {
      let tempName = this.ecgAlgoResult.userName;
      let tempStartTime = this.ecgAlgoResult.startTime;
      this.ecgAlgoResult = cloudEcgResult;
      this.ecgAlgoResult.userName = tempName;
      this.ecgAlgoResult.startTime = tempStartTime;
      let ecgComment = JSON.parse(cloudEcgResult.comment);
      let len = this.$store.state.isRawEcgArray ? this.$store.state.ecgRawArrayLength : this.$store.state.ecgFileArrayLength;
      let ecgDuration = len / this.sampleRage; // 采样点数/采样率 = 采集时长(s)
      if (!this.$utils.textIsEmpty(ecgComment.userName)) {
        this.ecgAlgoResult.userName = ecgComment.userName;
      }
      if (!this.$utils.textIsEmpty(ecgComment.startTime)) {
        this.ecgAlgoResult.startTime = ecgComment.startTime;
      }
      console.log(`isEcgArray = ${this.$store.state.isRawEcgArray}
       ecgRawLen = ${this.$store.state.ecgRawArrayLength}
       ecgFiltLen = ${this.$store.state.ecgFileArrayLength} ecgDuration = ${ecgDuration}`);
      this.ecgAlgoResult.ecgDuration = this.$utils.formatSecondsZh(ecgDuration);

      if (!this.$utils.textIsEmpty(this.ecgAlgoResult.startTime)) {
        let startDate = moment(this.ecgAlgoResult.startTime, ["YYYY-MM-DD HH:mm", "YYYY-MM-DD HH:mm:ss"]); //开始采集时间毫秒数
        console.log("start millisecond = " + startDate);
        let endDate = startDate + ecgDuration * 1000; //开始时间 + 采集时长 = 结束毫秒数
        console.log("end millisecond = " + endDate);
        this.ecgAlgoResult.endTime = moment(endDate).format("YYYY-MM-DD HH:mm:ss");
        console.log("moment(endDate).format) = " + moment(endDate).format("YYYY-MM-DD HH:mm:ss"));
        let highestHRIndexTimeMillis = startDate + (cloudEcgResult.highestHRIndex / this.sampleRage) * 1000; //心率最大时间
        let lowestHRIndexTimeMillis = startDate + (cloudEcgResult.lowestHRIndex / this.sampleRage) * 1000; //心率最小时间
        this.ecgAlgoResult.lowestHRIndexTime = moment(lowestHRIndexTimeMillis).format("YYYY-MM-DD HH:mm:ss");
        this.ecgAlgoResult.highestHRIndexTime = moment(highestHRIndexTimeMillis).format("YYYY-MM-DD HH:mm:ss");
      }
      console.log(this.ecgAlgoResult)
    },

    setLocalEcgResult() {
      let len = this.$store.state.isRawEcgArray ? this.$store.state.ecgRawArrayLength : this.$store.state.ecgFileArrayLength;
      let ecgDuration = len / this.sampleRage; // 采样点数/采样率 = 采集时长(s)
      console.log(`isEcgArray = ${this.$store.state.isRawEcgArray}
       ecgRawLen = ${this.$store.state.ecgRawArrayLength}
       ecgFiltLen = ${this.$store.state.ecgFileArrayLength} ecgDuration = ${ecgDuration}`);
      this.ecgAlgoResult.ecgDuration = this.$utils.formatSecondsZh(ecgDuration);

      if (!this.$utils.textIsEmpty(this.ecgAlgoResult.startTime)) {
        let startDate = moment(this.ecgAlgoResult.startTime, "YYYY-MM-DD HH:mm:ss"); //开始采集时间毫秒数
        console.log("start = " + startDate);
        let endDate = startDate + ecgDuration * 1000; //开始时间 + 采集时长 = 结束毫秒数
        console.log("end = " + endDate);
        this.ecgAlgoResult.endTime = moment(endDate).format("YYYY-MM-DD HH:mm:ss");

      }
      console.log(this.ecgAlgoResult)
    },


    //通过页面生成PDF
    onGeneratePDF() {
      console.log("onGeneratePDF");
      let pdfUrl = this.getPdf(this.ecgAlgoResult.userName + "动态心电报告");
      // let pdfUrl = pdf.output("datauristring");
      if (pdfUrl) {
        console.log(pdfUrl);
        // this.$store.state.pdf = pdf;
        // console.log(this.$store.state.pdf);
        let routerUrl = this.$router.resolve({
          path: "/PreviewPdf",
          query: {
            pdfUrl: pdfUrl,
          },
        });
        // window.open(routerUrl.href);
      }
    },

    /**
     * 获取李双心电报告
     */
    onGetPdf() {
      let that = this;
      const http = this.$axios.create({
        baseURL: '/pdf',
      });
      http.get(this.uuid + ".pdf").then(function (res) {
        console.log(res);
        console.log(typeof res.status);
        if (res.status === 200) {
          window.open(`https://xdsj.oss-cn-beijing.aliyuncs.com/apps/nap/singleBluetoothEcg/${that.uuid}.pdf`);
        } else {
          console.log("获取报告出错");
          that.$alert("报告暂未生成，请稍后再试", "获取PDF报告失败", {
            closeOnClickModal: true,
          })
        }
      }).catch(function (error) {
        // console.log("获取报告出错");
        // console.log(error)
        if (error.response.status === 404) { //报告暂未生成
          that.$alert("报告暂未生成，请稍后再试", "获取PDF报告失败", {
            closeOnClickModal: true,
          })
        }
      })
      // window.open(`https://xdsj.oss-cn-beijing.aliyuncs.com/apps/nap/singleBluetoothEcg/${this.uuid}.pdf`)
    },
    //上传患者信息使用李双算法
    uploadJsonInfo() {
      let that = this;
      if (this.$utils.textIsEmpty(this.uuid)) {
        this.$message.error("未找到文件uuid");
      } else {
        let info = {
          "orgid": "bb2d8079-2b25-4c20-9ebc-f6491f206de4",
          "patientId": this.uuid,
          "patientName": this.ecgAlgoResult.userName,
          "sex": "男",
          "birthday": "1979-06-25",
          "CreateDate": this.ecgAlgoResult.startTime,
          "pdTime": this.ecgAlgoResult.startTime,
          "dataType": "12导联心电"
        }
        console.log("upload info = " + JSON.stringify(info))

        const instance = this.$axios.create({
          baseURL: '/api',

        });
        instance.post("submitInfo.aspx", info,)
            .then(function (result) {
              console.log("*************************************")
              console.log(result.data);
              if (result.data === "success") {
                console.log("患者信息上传成功")
                that.isShowGetPdf = true;
              } else if (result.data === "patient info exsit") {
                console.log("患者信息已存在")
                that.isShowGetPdf = true;
              } else {
                that.isShowGetPdf = false;
              }
            }).catch(function (error) {
          console.log(error);
          that.isShowGetPdf = false;
        })

      }

    },
    /**
     * 下载滤波后Ecg文件
     */
    downloadFiltEcgFile() {
      let that = this;
      this.http.get(`analysis_local_ecg/${this.uuid}/filt_data.ecg`)
          .then(function (result) {
            if (result.status === 200) {
              console.log("filt_data download success");
              let data = result.data;
              // console.log(typeof data);
              // console.log(data);
              // that.filtEcgArray = new Int16Array(data);
              that.$store.commit("setEcgFiltArray",new Int16Array(data))
              // console.log(that.filtEcgArray)
              that.downloadBeatTypeFile(); //filt文件下载成功后下载波形特征文件
            } else {
              that.isLoading = false;
              that.$alert("filt_data download failed", "网络错误");
              console.log("filt_data download failed");
              that.$store.commit("setIsRawEcgArray",true);
              that.addEcgChartView();
            }

          }).catch(function (error) {
        console.log(error);
        that.isLoading = false;
        that.$alert("filt_data download failed", "网络错误");
        that.$store.commit("setIsRawEcgArray",true);
        that.addEcgChartView();
      })
    },
    /**
     * 下载波形特征文件
     */
    downloadBeatTypeFile() {
      let that = this;
      this.http.get(`analysis_local_ecg/${this.uuid}/beat_type.ann`)
          .then(function (result) {
            if (result.status === 200) {
              console.log("BeatTypeFile download success");
              let data = result.data;
              let beatArray = new Int8Array(data);
              let rBeatTypeArray = [];
              for (let i = 0, len = beatArray.length; i < len; i++) {
                rBeatTypeArray.push(String.fromCharCode(beatArray[i]))
              }
              that.$store.commit("setEcgRBeatTypeArray",rBeatTypeArray);
              // console.log(that.rBeatTypeArray)
              //最后下载R波位置文件
              that.downloadWaveChartFile()
            } else {
              that.isLoading = false;
              that.$alert("beat_type_data download failed", "网络错误");
              console.log("beat_type_data download failed");
              that.$store.commit("setIsRawEcgArray",true);
              that.addEcgChartView();
            }

          }).catch(function (error) {
        console.log(error)
        that.isLoading = false;
        that.$alert("beat_type_data download failed", "网络错误");
        that.$store.commit("setIsRawEcgArray",true);
        that.addEcgChartView();
      })
    },
    /**
     * 下载波形特征位置文件
     */
    downloadWaveChartFile() {
      let that = this;
      this.http.get(`analysis_local_ecg/${this.uuid}/wave_chars.ann`)
          .then(function (result) {
            if (result.status === 200) {
              console.log("WaveChartFile download success");
              let data = result.data;
              let fileArray = new Int8Array(data);
              let hrtArray = [];
              let rIndexArray = [];

              for (let i = 0,len = fileArray.length; i < len; i += 32) {
                let rIndexArr = fileArray.slice(i, i + 4);
                let hrtArr = fileArray.slice(i + 18, i + 20);
                let rIndex = that.bytes2Int(rIndexArr);
                let hrt = that.bytes2Short(hrtArr);
                hrtArray.push(hrt);
                rIndexArray.push(rIndex);
              }
              that.$store.commit("setEcgRIndexArray",rIndexArray);
              that.$store.commit("setEcgHrtArray",hrtArray);
              that.onAllFileDownloadComplete();
            } else {
              console.log("wave_chars download failed");
              that.isLoading = false;
              that.$alert("wave_chars download failed", "网络错误");
              that.$store.commit("setIsRawEcgArray",true);
              that.addEcgChartView();
            }

          }).catch(function (error) {
            console.log(error);
            that.isLoading = false;
            that.$alert("wave_chars download failed", "网络错误")
            that.$store.commit("setIsRawEcgArray",true);
            that.addEcgChartView();
      })
    },
    onAllFileDownloadComplete() {
      let ecgArrayTemp = new Int16Array(this.filtEcgArray.length);
      for (let i = 0; i < this.filtEcgArray.length; i++) {
        ecgArrayTemp[i] = this.filtEcgArray[i] * 0.5 + 2048;
      }
      let sliceArr = this.$utils.sliceEcgArray(ecgArrayTemp, this.ecgSliceSize);
      for (let i = 0; i < sliceArr.length; i++) {
        this.ecgArrayList[i] = sliceArr[i];
      }
      this.$store.commit("setIsRawEcgArray",false);
      this.addEcgChartView();
    },
    addEcgChartView() {
      // console.log(this)
      // let that = this;
      // for (let i = 0; i < this.ecgArrayList.length; i++) {
      //   let cmp = new (Vue.extend(EcgChartView))({
      //     propsData: {
      //       ecgArray: this.ecgArrayList[i],
      //       totalIndex: i,
      //       rIndexArray: this.rIndexArray,
      //       rBeatTypeArray: this.rBeatTypeArray,
      //       hrtArray: this.hrtArray,
      //       ecgViewId: "ecgview" + i,
      //       sampleRate: this.sampleRage,
      //       maxDataSize: this.$store.state.sliceSize,
      //     }
      //   }).$mount();
      //   document.getElementById("ecgbox").appendChild(cmp.$el);
      // }
      // setTimeout(function () {
      //   that.isLoading = false;
      // }, 200);

      // let that = this;
      //
      // let cmp = new (Vue.extend(PagingEcgChartView))({
      //   // propsData: {
      //   //   ecgArrayList: this.ecgArrayList,
      //   //   rIndexArray: this.rIndexArray,
      //   //   rBeatTypeArray: this.rBeatTypeArray,
      //   //   hrtArray: this.hrtArray,
      //   //   sampleRate: this.sampleRage,
      //   //   maxDataSize: this.$store.state.sliceSize,
      //   // }
      // }).$mount();
      //
      // document.getElementById("ecgbox").appendChild(cmp.$el);

      // setTimeout(function () {
      //   that.isLoading = false;
      // }, 200);

      this.$refs["paging-ecg-view"].loadData();
    },
    onClose() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
    },
    onPageChangeEnd() {
      this.isShowLoading = false;
      console.log("EcgView onPageChangeEnd = "+this.isLoading + " " + moment.now());
    },
    onPageChange(){
      // this.$nextTick(this.showLoading);
      let that = this;
      // that.showLoading();
      that.isShowLoading = true;
      console.log("onPageChange = "+that.isLoading + " "+ moment.now());
    },

  },

  created() {

    this.ecgSliceSize = this.$store.state.sliceSize;
    this.ecgFile = this.$store.state.ecgFile;
    this.sampleRate = this.$store.state.sampleRage;
    // this.waveChartFile = this.$store.state.waveChartFile;
    // this.beatTypeFile = this.$store.state.beatTypeFile;
    console.log( `EcgView created sliceSize = ${this.ecgSliceSize} sampleRate = ${this.sampleRate}`);
    // this.$eventBus.$on("page-change",this.onPageChange);
    this.$eventBus.$on("page-change-end",this.onPageChangeEnd);
  },

  mounted() {
    console.log("EcgView mounted")
    // this.openFile(this.waveChartFile);
    // this.openFile(this.beatTypeFile)
    if (this.ecgFile) {
      this.isLoading = true;
      this.openFile(this.ecgFile);
      // console.log("open file  -> start upload ecg file");
      // this.uploadEcgFile(this.ecgFile);
    } else {
      this.onClose();
    }

  },
  destroyed() {
    this.isLoading = false;
    this.ecgFile = null;
  }
};
</script>
<style scoped>
.ecg-view {
  width: 100%;
  /* height: 100%; */
  background: #525659;
}

#ecg-view-container {
  width: 1344px; /*1344px; 70%; */
  height: 1890px;
  margin: 0 auto;
  background: white;
  /* border: 1px solid #ffbebe; */
}

.ecg-view-title {
  text-align: center;
  background: #ffe6e6;
  height: 50px;
  line-height: 50px;
  font-size: 30px;
  margin-top: 20px;
}

.ecg-gain-speed {
  text-align: right;
  font-size: 20px;
  line-height: 20px;
  margin-right: 30px;
  margin-top: 5px;
}

.ecg-chart-view-container {
  /*height: 972px; !*每个心电图所占高度330px =  height300px + padding30px  *!*/
  /*overflow-y: scroll;*/
  /*overflow-x: hidden;*/
}

.bt-generate-pdf {
  width: 150px;
  position: fixed;
  right: 3%;
  top: 2%;
  min-width: 150px;
  min-height: 50px;
  /* display:none; */
}

.bt-back {
  position: fixed;
  left: 1%;
  top: 2%;
  /*border: 2px solid #409eff;*/
  /*border-radius: 50%;*/
}

.el-icon-back:hover {
  transform: scale(1.1, 1.1);
}

.el-icon-back:active {
  transform: scale(0.9, 0.9);
}
</style>
